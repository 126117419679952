import { useState } from "react";
import "../home/home.css";
import MainDisplay from "../../components/imageDisplay/MainDisplay";
import TherabodyLogo from "../../assets/PageAssets/Home/THERABODY-LOGO.svg";
import PelotonLogo from "../../assets/PageAssets/Home/PELOTON-LOGO.svg";
import MercedesLogo from "../../assets/PageAssets/Home/MERCEDES-LOGO.svg";

const Home = () => {
  const [projects] = useState([
    {
      projectName: "Therabody",
      projectSource: TherabodyLogo,
      projectPath: "/therabody",
      projectClass: "therabody",
    },
    {
      projectName: "Peloton",
      projectSource: PelotonLogo,
      projectPath: "/peloton",
      projectClass: "peloton",
    },
    {
      projectName: "Mercedes-Benz",
      projectSource: MercedesLogo,
      projectPath: "/mercedes-benz",
      projectClass: "mercedes-benz",
    },
  ]);

  const companyClass = "Home";
  const title = "Creative Copywriter from NJ, based in TN.";
  const subText = "If you can read it, I can write it.";

  return (
    <MainDisplay
      projects={projects}
      title={title}
      subText={subText}
      companyClass={companyClass}
    />
  );
};
export default Home;
