import { useState, useContext } from "react";
import SubPageNav from "../../../components/nav/SubPageNav";
import { WebPath } from "../../../components/context/appContext";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import MBGImage2 from "../../../assets/PageAssets/Mercedes-Benz/MBG-image-2.gif";
import "./lastFanStanding.css";

const LastFanStanding = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "video",
      projectSource:
        "https://player.vimeo.com/video/257190787?h=6d897eb4b3&title=0&byline=0&portrait=0",
      projectClass: "last-fan-standing-1",
    },
  ]);

  const projectNameOne = "last-fan-standing";
  const titleOne = "Last Fan Standing";
  const subTextOne =
    "What does it take to win the Mercedes-AMG C 43 Coupe? A phone, a finger, and lots of focus. Starting on Super Bowl Sunday, the last one with their finger on the car, wins it. ";

  const [mediaTwo] = useState([
    {
      projectType: "image",
      projectSource: MBGImage2,
      projectClass: "last-fan-standing-2",
    },
  ]);

  const projectNameTwo = "last-fan-standing2";
  const titleTwo = "Social Activations";
  const subTextTwo = "";

  const [mediaThree] = useState([
    {
      projectType: "video",
      projectSource:
        "https://player.vimeo.com/video/254900327?h=fabfb37948&title=0&byline=0&portrait=0",
      projectClass: "last-fan-standing-3",
    },
    {
      projectType: "video",
      projectSource:
        "https://player.vimeo.com/video/254902015?h=715caee456&title=0&byline=0&portrait=0",
      projectClass: "last-fan-standing-4",
    },
    {
      projectType: "video",
      projectSource:
        "https://player.vimeo.com/video/254900452?h=bfcd4d0097&title=0&byline=0&portrait=0",
      projectClass: "last-fan-standing-5",
    },
  ]);

  const projectNameThree = "last-fan-standing3";
  const titleThree = "";
  const subTextThree = "";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <ProjectDisplay
        media={mediaTwo}
        projectName={projectNameTwo}
        title={titleTwo}
        subText={subTextTwo}
      />
      <ProjectDisplay
        media={mediaThree}
        projectName={projectNameThree}
        title={titleThree}
        subText={subTextThree}
      />
      <SubPageNav projectPaths={projectPaths.mercedesBenz} />
    </div>
  );
};

export default LastFanStanding;
