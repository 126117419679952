import { useState, useContext } from "react";
import { WebPath } from "../../../components/context/appContext";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import SubPageNav from "../../../components/nav/SubPageNav";
import Apparel1 from "../../../assets/PageAssets/Peloton/Apparel-image-1.jpg";
import Apparel2 from "../../../assets/PageAssets/Peloton/Apparel-image-2.jpg";
import Apparel3 from "../../../assets/PageAssets/Peloton/Apparel-image-3.jpg";
import Apparel4 from "../../../assets/PageAssets/Peloton/Apparel-image-4.png";
import "./apparel.css";

const Apparel = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "image",
      projectSource: Apparel1,
      projectClass: "apparel-1",
    },
    {
      projectType: "image",
      projectSource: Apparel2,
      projectClass: "apparel-2",
    },
  ]);

  const projectNameOne = "apparel";
  const titleOne = "Spring 2019 Collection";
  const subTextOne =
    "Strive to be remembered in everything you do. Hit a new PR during class. Be the name to beat on the leaderboard. And carry that same force with you into your everyday life. Leave a lasting impression in the all-new Make Your Mark Apparel collection.";
  const [mediaTwo] = useState([
    {
      projectType: "image",
      projectSource: Apparel3,
      projectClass: "apparel-3",
    },
    {
      projectType: "image",
      projectSource: Apparel4,
      projectClass: "apparel-4",
    },
  ]);

  const projectNameTwo = "apparel2";
  const titleTwo = "Valentine's Day Collection";
  const subTextTwo = "";

  return (
    <div className={`${projectNameOne}-page`}>
      <div className={`hero-main-banner-wrapper ${projectNameOne}`}>
        <p className="hero-main-banner">Apparel</p>
      </div>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <ProjectDisplay
        media={mediaTwo}
        projectName={projectNameTwo}
        title={titleTwo}
        subText={subTextTwo}
      />

      <SubPageNav projectPaths={projectPaths.peloton} />
    </div>
  );
};

export default Apparel;
