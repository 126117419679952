import React, { useState } from "react";
import "./nav.css";
import dropDownLogo from "../../assets/dropDown.png";
import dropDownX from "../../assets/dropDownX.png";
import NavPaths from "./NavPaths";
import { Link } from "react-router-dom";

const Nav = () => {
  const [dropDown, setDropDown] = useState(false);
  const [dropDownButton, setDropDownButton] = useState(dropDownLogo);

  const openDropDown = () => {
    let app = document.querySelector("body");
    app.style.overflow = "hidden";
    setDropDown(!dropDown);
    setDropDownButton(dropDownX);
  };

  const closeDropDown = () => {
    let app = document.querySelector("body");
    app.style.overflow = "auto";
    setDropDown(!dropDown);
    setDropDownButton(dropDownLogo);
  };

  return (
    <div className="header">
      <div className="nav-title">
        <Link to="/" className="nav-name-marisa-salvemini">
          MARISA SALVEMINI
        </Link>
      </div>

      <img
        src={dropDownButton}
        className="nav-drop-down"
        alt=""
        onClick={() => {
          if (dropDown) {
            closeDropDown();
          } else {
            openDropDown();
          }
        }}
      ></img>

      {dropDown && (
        <div className="nav-mobile-background">
          <div className="nav-mobile-bottom">
            <NavPaths dropDown={dropDown} closeDropDown={closeDropDown} />
          </div>
        </div>
      )}
      <div className="nav-bottom">
        <NavPaths />
      </div>
    </div>
  );
};

export default Nav;
