import { useState, useContext } from "react";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import SubPageNav from "../../../components/nav/SubPageNav";
import { WebPath } from "../../../components/context/appContext";

import "./keepMoving.css";

const ValentinesDay = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "instagram",
      projectSource: "https://www.instagram.com/reel/CopoAiFLtnZ/embed",
      projectClass: "valentines-day-1",
    },
    {
      projectType: "instagram",
      projectSource: "https://www.instagram.com/reel/CoVBEoDJX3A/embed",
      projectClass: "valentines-day-2",
    },

    {
      projectType: "instagram",
      projectSource: "https://www.instagram.com/reel/CoaM0A-sx8v/embed",
      projectClass: "valentines-day-3",
    },
  ]);

  const projectNameOne = "valentines-day";
  const titleOne = "Valentine's Day";
  const subTextOne = "";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <SubPageNav projectPaths={projectPaths.therabody} />
    </div>
  );
};

export default ValentinesDay;
