import { useState, useEffect } from "react";
import "./subPageNav.css";
import { Link, useLocation } from "react-router-dom";

const SubPageNav = (props) => {
  const { projectPaths } = props;

  const location = useLocation();

  const [url, setUrl] = useState(null);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  let subPageNav = projectPaths.map((path) => (
    <Link
      to={path.projectPath}
      className={
        "project-name-container" + (url === path.projectPath ? " active" : "")
      }
      key={path.projectClass}
      onMouseOver={(e) => {
        e.currentTarget.style.borderBottom = "2px solid #333";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.borderBottom = "2px solid #f8f8f8";
      }}
    >
      <div className="project-name">{path.projectName}</div>
    </Link>
  ));

  return <div className="SubPageNav">{subPageNav}</div>;
};

export default SubPageNav;
