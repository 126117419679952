import { useState, useContext } from "react";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import SubPageNav from "../../../components/nav/SubPageNav";
import { WebPath } from "../../../components/context/appContext";
import KeepMoving2 from "../../../assets/PageAssets/Therabody/TB-Keep-Moving-2.png";
import KeepMoving3 from "../../../assets/PageAssets/Therabody/TB-Keep-Moving-3.png";
import KeepMoving4 from "../../../assets/PageAssets/Therabody/TB-Keep-Moving-4.png";
import KeepMoving5 from "../../../assets/PageAssets/Therabody/TB-Keep-Moving-5.png";

import "./keepMoving.css";

const KeepMoving = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "instagram",
      projectSource: "https://www.instagram.com/reel/CivDBZhAPYe/embed",
      projectClass: "keep-moving-1",
    },
    {
      projectType: "image",
      projectSource: KeepMoving2,
      projectClass: "keep-moving-2",
    },

    {
      projectType: "image",
      projectSource: KeepMoving3,
      projectClass: "keep-moving-3",
    },
    {
      projectType: "image",
      projectSource: KeepMoving4,
      projectClass: "keep-moving-4",
    },
    {
      projectType: "image",
      projectSource: KeepMoving5,
      projectClass: "keep-moving-5",
    },
  ]);

  const projectNameOne = "keep-moving";
  const titleOne = "Keep Moving";
  const subTextOne =
    "Therabody’s science-backed approach to wellness reinforces the idea that the mind and body are infinitely intertwined, and should be treated as such." +
    " With eight new product launches that support both physical movement and mental agility,  it was time to reposition our whole brand." +
    " Therabody helps keep you moving through life, and everything that comes with it.";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <SubPageNav projectPaths={projectPaths.therabody} />
    </div>
  );
};

export default KeepMoving;
