import { useState, useContext } from "react";
import { WebPath } from "../../../components/context/appContext";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import SubPageNav from "../../../components/nav/SubPageNav";
import Instructor1 from "../../../assets/PageAssets/Peloton/Instructor-image-1.gif";
import Instructor2 from "../../../assets/PageAssets/Peloton/Instructor-image-2.jpg";
import "./instructorLaunches.css";

const InstructorLaunches = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "image",
      projectSource: Instructor1,
      projectClass: "instructor-launches-1",
    },
    {
      projectType: "image",
      projectSource: Instructor2,
      projectClass: "instructor-launches-2",
    },
  ]);

  const projectNameOne = "instructor-launches";
  const titleOne = "Run Wild with Jess King";
  const subTextOne =
    "Jess King, known for her creative coaching on the Peloton Bike, is joining the Tread team.";

  return (
    <div className={`${projectNameOne}-page`}>
      <div className={`hero-main-banner-wrapper ${projectNameOne}`}>
        <p className="hero-main-banner">Instructor Launches</p>
      </div>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <div className="instructor-launches-link-container">
        <div className="instructor-launches-link-text">
          Click{" "}
          <a
            className="instructor-launches-link-highlighted-text"
            href="https://www.instagram.com/p/CFw9erwFRCC/"
          >
            here
          </a>{" "}
          for the launch video.
        </div>
      </div>
      <SubPageNav projectPaths={projectPaths.peloton} />
    </div>
  );
};

export default InstructorLaunches;
