import { useState, useContext } from "react";
import SubPageNav from "../../../components/nav/SubPageNav";
import { WebPath } from "../../../components/context/appContext";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import "./mercedesBenzGames.css";

const MercedesBenzGames = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "video",
      projectSource: "https://www.youtube.com/embed/6nBt3ukUOiU?controls=0",
      projectClass: "mercedes-benz-games-1",
    },
  ]);

  const projectNameOne = "mercedes-benz-games";
  const titleOne = "Mercedes-Benz Stadium Games: The Tour";
  const subTextOne =
    "Rickie Fowler took a tour of our brand new stadium, but no one could expect who was waiting for him behind the last door...";

  const [mediaTwo] = useState([
    {
      projectType: "video",
      projectSource: "https://www.youtube.com/embed/FJFBFv-M9QY?controls=0",
      projectClass: "mercedes-benz-games-2",
    },
  ]);

  const projectNameTwo = "mercedes-benz-games2";
  const titleTwo = "The Competitions";
  const subTextTwo =
    "Who won the first ever Mercedes-Benz Stadium Games, Matt Ryan or Rickie Fowler? You tell us.";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <ProjectDisplay
        media={mediaTwo}
        projectName={projectNameTwo}
        title={titleTwo}
        subText={subTextTwo}
      />
      <SubPageNav projectPaths={projectPaths.mercedesBenz} />
    </div>
  );
};

export default MercedesBenzGames;
