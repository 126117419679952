import { useState, useContext } from "react";
import SubPageNav from "../../../components/nav/SubPageNav";
import { WebPath } from "../../../components/context/appContext";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import Smart1 from "../../../assets/PageAssets/Mercedes-Benz/Smart-image-1.gif";
import Smart2 from "../../../assets/PageAssets/Mercedes-Benz/Smart-image-2.gif";
import "./smart.css";

const SmartUSA = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "image",
      projectSource: Smart1,
      projectClass: "smart-USA-1",
    },
    {
      projectType: "image",
      projectSource: Smart2,
      projectClass: "smart-USA-2",
    },
  ]);

  const projectNameOne = "smart-USA";
  const titleOne = "Smart USA";
  const subTextOne =
    "Smart gets knocked for being small, but we're guessing those skeptics have never actually been inside one." +
    " A smart car is surprisingly roomy. It can tackle holiday errands just as well as any SUV... maybe even better.";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <SubPageNav projectPaths={projectPaths.mercedesBenz} />
    </div>
  );
};

export default SmartUSA;
