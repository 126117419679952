import { useState } from "react";
import "../../components/imageDisplay/mainPage.css";
import MainDisplay from "../../components/imageDisplay/MainDisplay";
import PelothonMain from "../../assets/PageAssets/Peloton/Pelothon-Main.png";
import NewFeatureMain from "../../assets/PageAssets/Peloton/NFL-Main.png";
import BikePlus from "../../assets/PageAssets/Peloton/BikeP-Main.gif";
import Holiday from "../../assets/PageAssets/Peloton/Holiday-Main.jpg";
import InstructorLaunch from "../../assets/PageAssets/Peloton/Instructor-Main.jpg";
import Apparel from "../../assets/PageAssets/Peloton/Apparel-Main.png";

const Peloton = () => {
  const [projects] = useState([
    {
      projectName: "Pelothon 2020",
      projectSource: PelothonMain,
      projectPath: "/pelothon2020",
      projectClass: "pelothon",
    },
    {
      projectName: "New Feature Launches",
      projectSource: NewFeatureMain,
      projectPath: "/new-feature-launches",
      projectClass: "new-feature-launches",
    },
    {
      projectName: "Peloton Bike+ Launch",
      projectSource: BikePlus,
      projectPath: "/peloton-bike-launch",
      projectClass: "peloton-bike-launch",
    },
    {
      projectName: "Holiday",
      projectSource: Holiday,
      projectPath: "/holiday",
      projectClass: "holiday",
    },
    {
      projectName: "Instructor Launches",
      projectSource: InstructorLaunch,
      projectPath: "/instructor-launches",
      projectClass: "instructor-launches",
    },
    {
      projectName: "Apparel",
      projectSource: Apparel,
      projectPath: "/apparel",
      projectClass: "apparel",
    },
  ]);

  const companyClass = "Peloton";
  const title = "Peloton";
  const subText = "";

  return (
    <MainDisplay
      projects={projects}
      title={title}
      subText={subText}
      companyClass={companyClass}
    />
  );
};
export default Peloton;
