import { useState, useContext } from "react";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import Pelothon1 from "../../../assets/PageAssets/Peloton/Pelothon-image-1.png";
import Pelothon2 from "../../../assets/PageAssets/Peloton/Pelothon-image-2.png";
import Pelothon3 from "../../../assets/PageAssets/Peloton/Pelothon-image-3.gif";
import "./pelothon.css";
import SubPageNav from "../../../components/nav/SubPageNav";
import { WebPath } from "../../../components/context/appContext";

const Pelothon = (props) => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "instagram",
      projectSource: "https://www.instagram.com/p/CCTgxa_jo-Z/embed",
      projectClass: "pelothon-1",
    },
    {
      projectType: "text",
      projectText:
        "How do we get our new Members excited to be a part of the Peloton family, especially during a time when most of us were stuck inside? We created Pelothon with our partner agency, Mekanism, with this question in mind. It gave our Members the chance to join teams, complete some fun workout challenges and earn badges–all while making an impact in their community.",
      projectClass: "pelothon-2",
    },
  ]);

  const projectNameOne = "pelothon";
  const titleOne = "Pelothon 2020";
  const subTextOne = "";

  const [mediaTwo] = useState([
    {
      projectType: "image",
      projectSource: Pelothon1,
      projectClass: "pelothon-3",
    },
    {
      projectType: "image",
      projectSource: Pelothon2,
      projectClass: "pelothon-4",
    },
    {
      projectType: "image",
      projectSource: Pelothon3,
      projectClass: "pelothon-5",
    },
  ]);

  const projectNameTwo = "pelothon2";
  const titleTwo = "";
  const subTextTwo = `Announcement Email,   App Notification,   Instagram Story`;

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <ProjectDisplay
        media={mediaTwo}
        projectName={projectNameTwo}
        title={titleTwo}
        subText={subTextTwo}
      />
      <SubPageNav projectPaths={projectPaths.peloton} />
    </div>
  );
};

export default Pelothon;
