import { useState } from "react";
import MainDisplay from "../../components/imageDisplay/MainDisplay";
import FathersDay from "../../assets/PageAssets/Therabody/TB-Cody-Bellinger-Main.jpg";
import Revolve from "../../assets/PageAssets/Therabody/TB-Revolve-Main.jpg";
import KeepMoving from "../../assets/PageAssets/Therabody/TB-Keep-Moving-Main.gif";
import ValentinesDay from "../../assets/PageAssets/Therabody/TB-Valentine-main.png";
import "./therabody.css";

const Therabody = () => {
  const [projects] = useState([
    {
      projectName: "Cody Bellinger X Father's Day",
      projectSource: FathersDay,
      projectPath: "/cody-bellinger-fathers-day",
      projectClass: "cody-bellinger-fathers-day",
    },
    {
      projectName: "Revolve Wellness Oasis",
      projectSource: Revolve,
      projectPath: "/revolve",
      projectClass: "revolve",
    },
    {
      projectName: "Keep Moving",
      projectSource: KeepMoving,
      projectPath: "/keep-moving",
      projectClass: "keep-moving",
    },
    {
      projectName: "Valentine's Day",
      projectSource: ValentinesDay,
      projectPath: "/valentines-day",
      projectClass: "valentines-day",
    },
  ]);
  const companyClass = "Therabody";
  const title = "Therabody";
  const subText = "";
  return (
    <MainDisplay
      projects={projects}
      title={title}
      subText={subText}
      companyClass={companyClass}
    />
  );
};

export default Therabody;
