import { useState } from "react";
import "./mercedesBenz.css";
import MainDisplay from "../../components/imageDisplay/MainDisplay";
import LastFanMain from "../../assets/PageAssets/Mercedes-Benz/LFS_Main.jpg";
import EventsMain from "../../assets/PageAssets/Mercedes-Benz/Events-Main.gif";
import MBGMain from "../../assets/PageAssets/Mercedes-Benz/MBG-Main.png";
import SummmerMain from "../../assets/PageAssets/Mercedes-Benz/MBS-Main.gif";
import SmartMain from "../../assets/PageAssets/Mercedes-Benz/Smart-Main.jpg";

const MercedesBenz = () => {
  const [projects] = useState([
    {
      projectName: "Last Fan Standing",
      projectSource: LastFanMain,
      projectPath: "/last-fan-standing",
      projectClass: "last-fan-standing",
    },
    {
      projectName: "Events & Social Content",
      projectSource: EventsMain,
      projectPath: "/events-and-social",
      projectClass: "events-and-social",
    },
    {
      projectName: "The Mercedes-Benz Stadium Games",
      projectSource: MBGMain,
      projectPath: "/mercedes-benz-games",
      projectClass: "peloton-bike-launch",
    },
    {
      projectName: "#MBSummer",
      projectSource: SummmerMain,
      projectPath: "/summer",
      projectClass: "holiday",
    },
    {
      projectName: "Smart USA",
      projectSource: SmartMain,
      projectPath: "/smart-USA",
      projectClass: "instructor-launches",
    },
  ]);

  const companyClass = "Mercedes-Benz";
  const title = "Mercedes-Benz";
  const subText = "";

  return (
    <MainDisplay
      projects={projects}
      title={title}
      subText={subText}
      companyClass={companyClass}
    />
  );
};

export default MercedesBenz;
