import { useState, useContext } from "react";
import SubPageNav from "../../../components/nav/SubPageNav";
import { WebPath } from "../../../components/context/appContext";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import Summer1 from "../../../assets/PageAssets/Mercedes-Benz/MBS-image-1.png";
import Summer2 from "../../../assets/PageAssets/Mercedes-Benz/MBS-image-2.gif";
import Summer3 from "../../../assets/PageAssets/Mercedes-Benz/MBS-image-3.gif";
import "./summer.css";

const Summer = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "image",
      projectSource: Summer1,
      projectClass: "mercedes-summer-1",
    },
  ]);

  const projectNameOne = "mercedes-summer";
  const titleOne = "The Never-Ending #MBSummer";
  const subTextOne =
    "Wouldn’t it be great if summer never had to come to an end? Well, in a Mercedes-Benz, it never does." +
    " We asked our followers to keep our never-ending Instagram story going by submitting their photos using #MBSummer.";

  const [mediaTwo] = useState([
    {
      projectType: "image",
      projectSource: Summer2,
      projectClass: "mercedes-summer-2",
    },
    {
      projectType: "image",
      projectSource: Summer3,
      projectClass: "mercedes-summer-3",
    },
  ]);

  const projectNameTwo = "mercedes-summer2";
  const titleTwo = "";
  const subTextTwo = "";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <ProjectDisplay
        media={mediaTwo}
        projectName={projectNameTwo}
        title={titleTwo}
        subText={subTextTwo}
      />
      <SubPageNav projectPaths={projectPaths.mercedesBenz} />
    </div>
  );
};

export default Summer;
