import { useState, useContext } from "react";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import SubPageNav from "../../../components/nav/SubPageNav";
import { WebPath } from "../../../components/context/appContext";
import "./fathersDay.css";

const FathersDay = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "text",
      projectText:
        "Parents are up before the sun. They dart around the house like they’re doing suicide runs. Hoisting children up to check their diapers, squatting down to grab the toys from under the couch. Deep breaths are required. " +
        " No one ever tells you of the physical demands of parenthood. You got to move like you’re an athlete. And this Father’s Day, you can recover like one too.",
      projectClass: "cody-bellingers-fathers-day-1",
    },
    {
      projectType: "instagram",
      projectSource: "https://www.instagram.com/reel/CejSEEmpUWn/embed",
      projectClass: "cody-bellingers-fathers-day-2",
    },
  ]);

  const projectNameOne = "cody-bellingers-fathers-day";
  const titleOne = "Cody Bellinger x Father's Day";
  const subTextOne = "";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <SubPageNav projectPaths={projectPaths.therabody} />
    </div>
  );
};

export default FathersDay;
