import { useState, useContext } from "react";
import SubPageNav from "../../../components/nav/SubPageNav";
import { WebPath } from "../../../components/context/appContext";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import EventsImage1 from "../../../assets/PageAssets/Mercedes-Benz/Events-image-1.png";
import EventsImage2 from "../../../assets/PageAssets/Mercedes-Benz/Events-image-2.gif";
import EventsImage3 from "../../../assets/PageAssets/Mercedes-Benz/Events-image-3.png";

import "./eventsAndSocial.css";

const EventsAndSocial = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "instagram",
      projectSource: "https://www.instagram.com/p/BcIE5H9hSc7/embed",
      projectClass: "events-and-social-1",
    },
  ]);

  const projectNameOne = "events-and-social";
  const titleOne = "LA Auto Show: 2019 CLS Launch";
  const subTextOne = "";

  const [mediaTwo] = useState([
    {
      projectType: "image",
      projectSource: EventsImage1,
      projectClass: "events-and-social-2",
    },
  ]);

  const projectNameTwo = "events-and-social2";
  const titleTwo = "Faena Arts District";
  const subTextTwo =
    "Mercedes-Benz is the official automotive partner of the new Faena Arts District in Miami." +
    " The elements of art and design that are found in Miami can also be found throughout the Mercedes-Benz brand, so let’s treat each post like a work of art.";

  const [mediaThree] = useState([
    {
      projectType: "image",
      projectSource: EventsImage2,
      projectClass: "events-and-social-3",
    },
  ]);

  const projectNameThree = "events-and-social3";
  const titleThree = "Mercedes-AMG Instagram Story Series";
  const subTextThree = "";

  const [mediaFour] = useState([
    {
      projectType: "image",
      projectSource: EventsImage3,
      projectClass: "events-and-social-4",
    },
  ]);

  const projectNameFour = "events-and-social4";
  const titleFour = "2018 Grammys";
  const subTextFour = "";

  return (
    <div className={`${projectNameOne}-page`}>
      <div className={`hero-main-banner-wrapper ${projectNameOne}`}>
        <p className="hero-main-banner">Events & Social Content</p>
      </div>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <ProjectDisplay
        media={mediaTwo}
        projectName={projectNameTwo}
        title={titleTwo}
        subText={subTextTwo}
      />
      <ProjectDisplay
        media={mediaThree}
        projectName={projectNameThree}
        title={titleThree}
        subText={subTextThree}
      />
      <ProjectDisplay
        media={mediaFour}
        projectName={projectNameFour}
        title={titleFour}
        subText={subTextFour}
      />
      <SubPageNav projectPaths={projectPaths.mercedesBenz} />
    </div>
  );
};

export default EventsAndSocial;
