import Dramamine1 from "../../../assets/PageAssets/Additional_Work/DRAMAMINE-Main-image-1.jpg";
import Dramamine2 from "../../../assets/PageAssets/Additional_Work/DRAMAMINE-image-2.jpg";
import Dramamine3 from "../../../assets/PageAssets/Additional_Work/DRAMAMINE-image-3.jpg";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import { useState, useContext } from "react";
import { WebPath } from "../../../components/context/appContext";
import SubPageNav from "../../../components/nav/SubPageNav";

const Dramamine = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "image",
      projectSource: Dramamine1,
      projectClass: "dramamine-1",
    },
    {
      projectType: "image",
      projectSource: Dramamine2,
      projectClass: "dramamine-2",
    },
    {
      projectType: "image",
      projectSource: Dramamine3,
      projectClass: "dramamine-3",
    },
  ]);

  const projectNameOne = "dramamine";
  const titleOne = "Dramamine";
  const subTextOne = "Motion Sickness relief.";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <SubPageNav projectPaths={projectPaths.additionalWork} />
    </div>
  );
};

export default Dramamine;
