import DramamineMain from "../../assets/PageAssets/Additional_Work/DRAMAMINE-Main-image-1.jpg";
import GoogleFlightsMain from "../../assets/PageAssets/Additional_Work/GF-Main.jpg";
import MainDisplay from "../../components/imageDisplay/MainDisplay";
import { useState } from "react";

const Internship = () => {
  const [projects] = useState([
    {
      projectName: "Dramamine",
      projectSource: DramamineMain,
      projectPath: "/dramamine",
      projectClass: "dramamine",
    },
    {
      projectName: "Google Flights",
      projectSource: GoogleFlightsMain,
      projectPath: "/google-flights",
      projectClass: "google-flights",
    },
  ]);

  const companyClass = "Internship";
  const title = "Internship";
  const subText = "";

  return (
    <div className={companyClass}>
      <MainDisplay
        projects={projects}
        title={title}
        subText={subText}
        companyClass={companyClass}
      />
    </div>
  );
};

export default Internship;
