import { Link } from "react-router-dom";

const MainDisplay = (props) => {
  const { projects, title, subText, companyClass } = props;

  let portfolioProjects = projects.map((project) => (
    <Link
      to={project.projectPath}
      className={`portfolio-image-container ${project.projectClass}`}
      key={project.projectName}
    >
      <img
        src={project.projectSource}
        className={`portfolio-image ${project.projectClass}`}
        alt=""
      ></img>
      <div
        className={`overlay ${project.projectClass}`}
        onMouseOver={(e) => {
          e.currentTarget.style.opacity = 1;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.opacity = 0;
        }}
      >
        <div className="text">{project.projectName}</div>
      </div>
    </Link>
  ));

  return (
    <div className={companyClass}>
      <div className="hero-banner-container">
        <p className="hero-banner-1">{title}</p>
        <p className="hero-banner-1">{subText}</p>
      </div>
      <div className="portfolio-main">{portfolioProjects}</div>
    </div>
  );
};

export default MainDisplay;
