import { useState, useContext } from "react";
import { WebPath } from "../../../components/context/appContext";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import SubPageNav from "../../../components/nav/SubPageNav";
import NFL1 from "../../../assets/PageAssets/Peloton/NFL-image-1.gif";
import NFL2 from "../../../assets/PageAssets/Peloton/NFL-image-2.png";
import NFL3 from "../../../assets/PageAssets/Peloton/NFL-image-3.png";
import "./newFeatureLaunches.css";

const NewFeatureLaunches = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "image",
      projectSource: NFL1,
      projectClass: "new-feature-launches-1",
    },
    {
      projectType: "image",
      projectSource: NFL2,
      projectClass: "new-feature-launches-2",
    },
  ]);

  const projectNameOne = "new-feature-launches";
  const titleOne = "New Feature Launches";
  const subTextOne = "New Bike Home Screen";

  const [mediaTwo] = useState([
    {
      projectType: "image",
      projectSource: NFL3,
      projectClass: "new-feature-launches-3",
    },
  ]);

  const projectNameTwo = "new-feature-launches2";
  const titleTwo = "Track Love Bike Screen Notification";
  const subTextTwo = "";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <ProjectDisplay
        media={mediaTwo}
        projectName={projectNameTwo}
        title={titleTwo}
        subText={subTextTwo}
      />

      <SubPageNav projectPaths={projectPaths.peloton} />
    </div>
  );
};

export default NewFeatureLaunches;
