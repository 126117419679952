import GoogleFlights1 from "../../../assets/PageAssets/Additional_Work/GF-image-1.png";
import GoogleFlights2 from "../../../assets/PageAssets/Additional_Work/GF-image-2.png";
import "../../../components/imageDisplay/projectPage.css";
import "./googleFlights.css";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import { useState, useContext } from "react";
import { WebPath } from "../../../components/context/appContext";
import SubPageNav from "../../../components/nav/SubPageNav";

const GoogleFlights = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "image",
      projectSource: GoogleFlights1,
      projectClass: "google-flights-1",
    },
    {
      projectType: "image",
      projectSource: GoogleFlights2,
      projectClass: "google-flights-2",
    },
    {
      projectType: "video",
      projectSource:
        "https://player.vimeo.com/video/187206238?h=e4f13b6df9&title=0&byline=0&portrait=0",
      projectClass: "google-flights-3",
    },
  ]);

  const projectNameOne = "google-flights";
  const titleOne = "Google Flights";
  const subTextOne =
    "You don't need a plan, or an itinerary. You just need inspiration. ";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <SubPageNav projectPaths={projectPaths.additionalWork} />
    </div>
  );
};

export default GoogleFlights;
