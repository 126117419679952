import { useState, useContext } from "react";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import SubPageNav from "../../../components/nav/SubPageNav";
import { WebPath } from "../../../components/context/appContext";
import Revolve2 from "../../../assets/PageAssets/Therabody/TB-Revolve-2.png";
import "./revolve.css";

const Revolve = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "instagram",
      projectSource: "https://www.instagram.com/p/CcyiN2APaCb/embed",
      projectClass: "revolve-1",
    },
    {
      projectType: "image",
      projectSource: Revolve2,
      projectClass: "revolve-2",
    },
  ]);

  const projectNameOne = "revolve-wellness";
  const titleOne = "Revolve Wellness Oasis";
  const subTextOne =
    "Therabody teamed up with Revolve to put on a private event for Influencers during Coachella. After a long day in the desert, we kept festival goers feeling refreshed and revived all weekend long. ";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <SubPageNav projectPaths={projectPaths.therabody} />
    </div>
  );
};

export default Revolve;
