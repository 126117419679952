import { useState, useContext } from "react";
import { WebPath } from "../../../components/context/appContext";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import SubPageNav from "../../../components/nav/SubPageNav";
import Holiday1 from "../../../assets/PageAssets/Peloton/Holiday-image-1.jpg";
import Holiday2 from "../../../assets/PageAssets/Peloton/Holiday-image-2.png";
import Holiday3 from "../../../assets/PageAssets/Peloton/Holiday-image-3.png";
import Holiday4 from "../../../assets/PageAssets/Peloton/Holiday-image-4.png";
import "./holiday.css";

const Holiday = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "image",
      projectSource: Holiday1,
      projectClass: "holiday-1",
    },
    {
      projectType: "image",
      projectSource: Holiday2,
      projectClass: "holiday-2",
    },
  ]);

  const projectNameOne = "holiday";
  const titleOne = "The Peloton Spirit";
  const subTextOne =
    "Underneath every milestone is the inner drive that gets us there. Behind every new record is the dedication that propels us forward. When we hit our best time, that moment stays with us long after it's over." +
    " Looking beyond the miles and the minutes, the numbers we strive to hit. What we have left is an unforgettable feeling. That's the Peloton Spirit. This holiday, let's find it together.";

  const [mediaTwo] = useState([
    {
      projectType: "image",
      projectSource: Holiday3,
      projectClass: "holiday-3",
    },
    {
      projectType: "image",
      projectSource: Holiday4,
      projectClass: "holiday-4",
    },
  ]);

  const projectNameTwo = "holiday2";
  const titleTwo = "Window Vinyl";
  const subTextTwo = "";

  return (
    <div className={`${projectNameOne}-page`}>
      <div className={`hero-main-banner-wrapper ${projectNameOne}`}>
        <p className="hero-main-banner">Holiday</p>
      </div>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <ProjectDisplay
        media={mediaTwo}
        projectName={projectNameTwo}
        title={titleTwo}
        subText={subTextTwo}
      />

      <SubPageNav projectPaths={projectPaths.peloton} />
    </div>
  );
};

export default Holiday;
