import { useState, useContext } from "react";
import { WebPath } from "../../../components/context/appContext";
import ProjectDisplay from "../../../components/imageDisplay/ProjectDisplay";
import SubPageNav from "../../../components/nav/SubPageNav";
import BikeP from "../../../assets/PageAssets/Peloton/Bikep-image1.jpg";
import "./bikeLaunch.css";

const BikeLaunch = () => {
  const { projectPaths } = useContext(WebPath);

  const [mediaOne] = useState([
    {
      projectType: "image",
      projectSource: BikeP,
      projectClass: "bike-plus-launch-1",
    },
  ]);

  const projectNameOne = "bike-plus-launch";
  const titleOne = "Peloton Bike+ Launch";
  const subTextOne = "Direct Mail";

  return (
    <div className={`${projectNameOne}-page`}>
      <ProjectDisplay
        media={mediaOne}
        projectName={projectNameOne}
        title={titleOne}
        subText={subTextOne}
      />
      <div className="bike-plus-link-container">
        <div className="bike-plus-link-text">
          Click{" "}
          <a
            className="bike-plus-link-highlighted-text"
            href="https://www.onepeloton.com/bike-plus"
          >
            here
          </a>{" "}
          for the Bike+ site.
        </div>
      </div>
      <SubPageNav projectPaths={projectPaths.peloton} />
    </div>
  );
};

export default BikeLaunch;
