const ProjectDisplay = (props) => {
  const { media, projectName, title, subText } = props;

  let projects = media.map((medium) => {
    if (medium.projectType === "image") {
      return (
        <div
          className={`project-image-container ${medium.projectClass}`}
          key={medium.projectClass}
        >
          <img
            src={medium.projectSource}
            className={`project-image ${medium.projectClass}`}
            alt=""
          ></img>
        </div>
      );
    } else if (medium.projectType === "video") {
      return (
        <div
          className={`project-video-container ${medium.projectClass}`}
          key={medium.projectClass}
        >
          <iframe
            src={medium.projectSource}
            className={`project-video ${medium.projectClass}`}
            width="700"
            height="250"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            title={medium.projectClass}
          ></iframe>
        </div>
      );
    } else if (medium.projectType === "instagram") {
      return (
        <div
          className={`project-instagram-container ${medium.projectClass}`}
          key={medium.projectClass}
        >
          <iframe
            width="400"
            height="553"
            src={medium.projectSource}
            className={`project-instagram ${medium.projectClass}`}
            frameBorder="0"
            title={medium.projectClass}
            scrolling="no"
          ></iframe>
        </div>
      );
    } else if (medium.projectType === "text") {
      return (
        <div
          className={`project-text-container ${medium.projectClass}`}
          key={medium.projectClass}
        >
          <div className={`project-text ${medium.projectClass}`}>
            {medium.projectText}
          </div>
        </div>
      );
    }
  });

  return (
    <div className={`project-container ${projectName}`}>
      <div className={`hero-banner-wrapper ${projectName}`}>
        <p className="hero-banner-1">{title}</p>
        <p className="hero-banner-2">{subText}</p>
      </div>
      <div className={`project-main ${projectName}`}>{projects}</div>
    </div>
  );
};

export default ProjectDisplay;
