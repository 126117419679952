import Portrait from "../../assets/PageAssets/Contact/portrait.png";
import "./contact.css";

const Contact = () => {
  return (
    <div className="Contact">
      <img src={Portrait} className="portrait" alt=""></img>
      <div className="contact-overlay">
        <div className="contact-overlay-text">Marisa Salvemini</div>
      </div>
      <div className="contact-text-container">
        <div>
          Just a Jersey girl with a love for giant dogs, true crime, holistic
          health, and 24/7 diners.
        </div>
        <div>
          In my 10+ years of experience, I've written for a wide range of brands
          including fitness, tech, auto, mobile, banking, CPG, health &
          wellness, and more. My copywriting expertise ranges from large scale
          campaigns down to email subject lines, and pretty much everything else
          in between.
        </div>
        <div>
          {" "}
          I approach every project with purpose and intention, and work with the
          client to ensure the final product is on brand and drives results.
        </div>
        <div>
          <strong>Say hi!</strong>
          <div>marisasalvemini@gmail.com</div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
